export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDEySQ0gEXFrUpwJqKPuy1ZPJOvvC8gX4c",
    authDomain: "alphie-shikwambana.firebaseapp.com",
    databaseURL: "https://alphie-shikwambana.firebaseio.com",
    projectId: "alphie-shikwambana",
    storageBucket: "alphie-shikwambana.appspot.com",
    messagingSenderId: "1020792249355",
    appId: "1:1020792249355:web:30390b069c44b4a5c9fb0e",
    measurementId: "G-47ZQ5R55W0"
  }
};
